

import Vue from "vue";
import { IUser, EMPTY_USER, IUserUpdate } from "@common/user";
import { IStation } from "@common/station";
import axios, { AxiosRequestConfig, AxiosResponse } from "node_modules/axios";
import store from "@/store";
import { IServerRES } from "@common/server";
import { ServerError } from "@common/errors";

type ResolveFunction = (value: IUserUpdate) => void;
type RejectFunction = (value: Error) => void;

export default Vue.extend({
	name: "EditUserDialog",
	data: () => ({
		dialog: false as boolean,
		resolve: null as ResolveFunction|null,
		reject: null as RejectFunction|null,
		user: { ...EMPTY_USER } as IUser,
		userGasStations: [] as IStation[],
		initialUserGasStations: [] as IStation[],
		gasStations: [] as IStation[],
		hidePassword: true as boolean
	}),
	async created () {
		await this.fetchStations();
	},
	methods: {
		async open (user: IUser): Promise<IUserUpdate> {
			this.user = { ...user };
			await this.fetchUserStations();
			this.dialog = true;
			return new Promise((resolve, reject) => {
				this.resolve = resolve;
				this.reject = reject;
			});
		},
		clearPromise () {
			this.resolve = null;
			this.reject = null;
		},
		clearVars () {
			this.user = { ...EMPTY_USER };
			this.hidePassword = true;
			this.userGasStations = [];
		},
		agree () {
			if (this.resolve) {
				this.resolve({
					user: this.user,
					userGasStations: this.userGasStations,
					initialUserGasStations: this.initialUserGasStations
				});
			}
			this.dialog = false;
			this.clearPromise();
			this.clearVars();
		},
		cancel () {
			if (this.reject) {
				this.reject(new Error("User Cancel"));
			}
			this.dialog = false;
			this.clearPromise();
			this.clearVars();
		},
		async fetchStations () {
			try {
				const options: AxiosRequestConfig = {
					method: "GET",
					headers: {
						Authorization: `Bearer ${localStorage.getItem("token")}`
					},
					url: `${store.getters.serverURL}/station/all`,
				};
				const res: AxiosResponse<IServerRES<IStation[]>> = await axios(options);
				if (res.data.err === ServerError.NO_ERROR) {
					this.gasStations = res.data.payload.map((item) => {
						item.name = `(${item.id}) ${item.name}`;

						return item;
					});
				}
			} catch (err) {
				console.error(err);
			}
		},
		async fetchUserStations () {
			try {
				const data: IUser = this.user;
				const options: AxiosRequestConfig = {
					method: "POST",
					headers: {
						Authorization: `Bearer ${localStorage.getItem("token")}`
					},
					data,
					url: `${store.getters.serverURL}/station/all/user`,
				};
				const res: AxiosResponse<IServerRES<IStation[]>> = await axios(options);
				if (res.data.err === ServerError.NO_ERROR) {
					this.userGasStations = res.data.payload.map((item) => {
						item.name = `(${item.id}) ${item.name}`;

						return item;
					});
					this.initialUserGasStations = JSON.parse(JSON.stringify(this.userGasStations));
				}
			} catch (err) {
				console.error(err);
			}
		}
	},
});
