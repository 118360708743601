import { IStation } from "./station"

export interface IUser {
    uuid: string;
    username: string;
    email: string;
    password?: string;
    token?: string;
    role: string;
}

export const NO_USER:IUser = {
    uuid: "no-uuid",
    username: "no-username",
    email: "no-email",
    password: "no-password",
    role: "no-privileges"
}

export const EMPTY_USER:IUser = {
    uuid: "",
    username: "",
    email: "",
    password: "",
    role: ""
}

export interface ILogin {
    username?: string,
    password?: string
}

export interface IUserToken {
    token: string;
}

export interface IUserUpdate {
    user: IUser,
    userGasStations?: IStation[],
    initialUserGasStations?: IStation[]
}


export interface ILoginRES {
    token: string;
    role: string;
}

export interface IVerifyRES {
	role: string
}

export interface IUserDeleteRES {
    succeeded: boolean;
}

export interface IUserUpdateRES {
    succeeded: boolean;
    user: IUser;
}

export interface IUserChangePassword {
    currentPassword: string;
    newPassword: string;
}

export interface IUserChangeRES {
    succeeded: boolean;
}

export interface IUserDeleteRES {
    succeeded: boolean;
}