
import Vue from "vue";
import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import { IUser, IUserUpdate, IUserUpdateRES, IUserDeleteRES } from "@common/user";
import { IServerRES } from "@common/server";
import { ServerError } from "@common/errors";
import ConfirmationDialog, { ConfirmationDialogOptions } from "@/components/common/ConfirmationDialog.vue";
import AddUserDialog from "@/components/users/AddUserDialog.vue";
import EditUserDialog from "@/components/users/EditUserDialog.vue";

class TableHeader {
	constructor (
		public readonly text: string,
		public readonly align: string,
		public readonly sortable: boolean,
		public readonly value: string
	) {}
}

export default Vue.extend({
	name: "Users",
	components: {
		"confirmation-dialog": ConfirmationDialog,
		"add-user-dialog": AddUserDialog,
		"edit-user-dialog": EditUserDialog
	},
	data: () => {
		return {
			search: "" as string,
			tableLoading: false as boolean,
			headers: [
				new TableHeader("Username", "start", true, "username"),
				new TableHeader("Email", "start", true, "email"),
				new TableHeader("Role", "start", true, "role"),
				new TableHeader("Actions", "start", false, "actions")
			],
			footerProps: {
				"items-per-page-options": [15, 30, 50, -1],
			},
			snackbar: {
				show: false as boolean,
				text: "" as string,
				color: "primary" as string,
			}
		};
	},
	created: async function () {
		this.store.dispatch.changeAppTitle("Users");
		await this.store.dispatch.fetchUsers();
	},
	methods: {
		async addItem () {
			try {
				const data: IUserUpdate = await (this.$refs.addUser as InstanceType<typeof AddUserDialog>).open();
				const options: AxiosRequestConfig = {
					method: "POST",
					headers: {
						Authorization: `Bearer ${localStorage.getItem("token")}`
					},
					data,
					url: `${this.store.getters.serverURL}/user/add`,
				};
				const res: AxiosResponse<IServerRES<IUserUpdateRES>> = await axios(options);
				if (res.data.err === ServerError.NO_ERROR) {
					if (res.data.payload.succeeded) {
						const { user } = res.data.payload;
						this.store.dispatch.addUser(user);
						this.snackbar = {
							show: true,
							color: "success",
							text: "User was succesfully added.",
						};
					} else {
						this.snackbar = {
							show: true,
							color: "error",
							text: "Error! Try again!",
						};
					}
				}
			} catch (err) {
				if (err.message !== "User Cancel") {
					console.error(err);
					this.snackbar = {
						text: err.message,
						color: "error",
						show: true,
					};
				}
			}
		},
		async editItem (item: IUser) {
			try {
				const data: IUserUpdate = await (this.$refs.editUser as InstanceType<typeof EditUserDialog>).open(item);
				const options: AxiosRequestConfig = {
					method: "POST",
					headers: {
						Authorization: `Bearer ${localStorage.getItem("token")}`
					},
					data,
					url: `${this.store.getters.serverURL}/user/update`,
				};
				const res: AxiosResponse<IServerRES<IUserUpdateRES>> = await axios(options);
				if (res.data.err === ServerError.NO_ERROR) {
					if (res.data.payload.succeeded) {
						const { user } = res.data.payload;
						this.store.dispatch.editUser({
							old: item,
							new: user
						});
						this.snackbar = {
							show: true,
							color: "success",
							text: "User was succesfully edited.",
						};
					} else {
						this.snackbar = {
							show: true,
							color: "error",
							text: "Error! Try again!",
						};
					}
				}
			} catch (err) {
				if (err.message !== "User Cancel") {
					console.error(err);
					this.snackbar = {
						text: err.message,
						color: "error",
						show: true,
					};
				}
			}
		},
		async deleteItem (item: IUser) {
			try {
				const dialogTitle = "User delete";
				const dialogMessage = `Are you sure you want to delete the user: "${item.username}"?`;
				const dialogOptions: ConfirmationDialogOptions = {
					color: "error",
					width: 400,
					agree: {
						text: "Delete",
						color: "error",
						icon: "delete_forever",
					},
					cancel: {
						text: "Cancel",
						color: "secondary",
						icon: "close",
					}
				};
				const deleteConfirmation = await (this.$refs.deleteUser as InstanceType<typeof ConfirmationDialog>).open(
					dialogTitle,
					dialogMessage,
					dialogOptions
				);
				if (deleteConfirmation) {
					const data: IUser = item;
					const options: AxiosRequestConfig = {
						method: "POST",
						headers: {
							Authorization: `Bearer ${localStorage.getItem("token")}`
						},
						data,
						url: `${this.store.getters.serverURL}/user/delete`,
					};
					const res: AxiosResponse<IServerRES<IUserDeleteRES>> = await axios(options);
					if (res.data.err === ServerError.NO_ERROR) {
						if (res.data.payload.succeeded) {
							this.store.dispatch.deleteUser(item);
						}

						this.snackbar = {
							show: true,
							color: "success",
							text: "User was succesfully deleted.",
						};
					}
				}
			} catch (err) {
				if (err.message !== "User Cancel") {
					console.error(err);
					this.snackbar = {
						text: err.message,
						color: "error",
						show: true,
					};
				}
			}
		},
	}
});
